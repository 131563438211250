import { createStore } from 'vuex'

export const LOGIN_MUTATION = 'LOGIN_MUTATION'
export const LOGOUT_MUTATION = 'LOGOUT_MUTATION'
export const TOKEN_MUTATION = 'TOKEN_MUTATION'

export const store = createStore({
    state: {
        user: null,
        jwtToken: '',
    },
    getters: {
        isAuthenticated(state) {
            return state.user !== null
        },
    },
    mutations: {
        [LOGIN_MUTATION](state, user) {
            state.user = user
        },
        [TOKEN_MUTATION](state, token) {
            state.jwtToken = token
        },
        [LOGOUT_MUTATION](state) {
            state.user = null
            state.jwtToken = ''
        },
    }
})