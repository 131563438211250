import { createWebHistory, createRouter } from "vue-router";
import About from "@/views/About.vue";
import Login from "@/views/Login.vue";
import Search from "@/views/Search.vue";
import Namus from "@/views/Namus.vue";
import NamuDetail from '@/views/NamuDetail.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import firebase from "firebase/compat/app";

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth: boolean
  }
}

const routes = [
  {
    path: "/",
    name: "home",
    component: About,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/namus",
    name: "namus",
    component: Namus,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/namus/:id",
    name: "namu-detail",
    component: NamuDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (to.name == "Login" && firebase.auth().currentUser) {
    return {
      path: '/search'
    }
  }

  if (to.meta.requiresAuth && !firebase.auth().currentUser) {
    return {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  }
})

export default router