
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'
import "firebaseui/dist/firebaseui.css"

export default {
    mounted() {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())

        const uiConfig = {
            signInSuccessUrl: "/search",
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                    disableSignUp: {
                        status: true,
                        adminEmail: "contact@namusearch.com",
                        helpLink: "",
                    }
                },
                //firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            ],
            privacyPolicyUrl: '/privacy',
        }

        ui.start("#firebase-auth", uiConfig)
    },
}
