import { createApp, App as Application } from 'vue'
import { store } from './store'
import router from './routes'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import firebase from "firebase/compat/app";
import { LOGIN_MUTATION, LOGOUT_MUTATION, TOKEN_MUTATION } from './store'

const firebaseConfig = {
  apiKey: "AIzaSyAf1ThIeu3qfzNgrIWkUcHJjjf8Psx6O3E",
  authDomain: "namusearch.firebaseapp.com",
  projectId: "namusearch",
  storageBucket: "namusearch.appspot.com",
  messagingSenderId: "446565728628",
  appId: "1:446565728628:web:efc9df7807f2250498ec21",
  measurementId: "G-85ST2M5G0S"
};
firebase.initializeApp(firebaseConfig);

let app: Application

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.commit(LOGIN_MUTATION, user)
  } else {
    store.commit(LOGOUT_MUTATION)
  }

  if (!app) {
    app = createApp(App)
    app.use(ElementPlus)
      .use(store)
      .use(router)
      .mount("#app")
  }
});

firebase.auth().onIdTokenChanged((user) => {
  if (user) {
    user.getIdToken().then((token) => {
      store.commit(TOKEN_MUTATION, token)
    })
  }
})