<template>
    <div class="privacy-area">
        <h2>Privacy Policy</h2>
        <div class="content">
            <p>
                We care about user privacy and users' rights. We only collect as much as data as needed to provide the search service.
                All data will be stored for only as long as needed and will be deleted permanently as soon as possible or upon request.
            </p>
            <h3>What data is collected</h3>
            <p>
                We collect and store the email address and password that you give in the process of creating an account.
                We also collect and store the email address used to join the waiting list.
            </p>
            <h3>How your data is stored</h3>
            <p>
                Data is encrypted and stored securely in a Google Cloud database located in central US.
            </p>
            <h3>How your data is used</h3>
            <p>
                Your email address is used for account login and so that we can send you emails about namusearch.
                It is never shared with third parties.
            </p>
            <h3>How you can delete your data</h3>
            <p>
                Please send an email to contact@namusearch.com requesting your account (or email if you joined the waiting list) to be deleted.
                Account deletions will be automated from your account options page in the future.
            </p>
            <h3>Questions</h3>
            <p>
                If you have questions about your data feel free to contact us at contact@namusearch.com
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.privacy-area {
    min-height: 100vh;
}
</style>
