<template>
    <div class="namu-detail">
        <el-page-header :icon="ArrowLeft" content="namu title"/>
        <el-button type="danger">Delete</el-button>
        <div class="namu-description">
            This is a short description of the namu
        </div>
        <div class="namu-link-list">
            <ul>
                <li>http://website.com</li>
                <li>http://website2.com</li>
                <li>http://website3.com</li>
            </ul>
        </div>
    </div>
</template>