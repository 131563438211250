<template>
    <div class="namu-ideas">
        <div class="subtitle">namu ideas</div>
        <div class="idea-area">
            <el-row :gutter="30">
                <el-col :span="12" :xs="24" class="idea-col">
                    <el-row :gutter="12">
                        <el-col :span="8" :xs="24">
                            <el-tag size="large" effect="dark">hobby websites</el-tag>
                        </el-col>
                        <el-col :span="8" :xs="24">
                            <el-tag size="large" effect="dark" type="info">research websites</el-tag>
                        </el-col>
                        <el-col :span="8" :xs="24">
                            <el-tag size="large" effect="dark" type="danger">news websites</el-tag>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12" :xs="24">
                            <el-tag size="large" effect="dark" type="warning">social community websites</el-tag>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <el-tag size="large" effect="dark">replace old webrings</el-tag>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="8" :xs="24">
                            <el-tag size="large" effect="dark" type="success">favourite blogs</el-tag>
                        </el-col>
                    </el-row>
                </el-col>
                 <el-col :span="12" :xs="24">
                    <img src="@/assets/art.svg" class="cta-image" alt="a woman deciding which trees to plant"/>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.subtitle {
    font-weight: 600;
    font-size: 24px;
}
.el-tag {
    width: 100%;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}
.idea-col {
    margin-top: 40px;
}
.cta-image {
  width: 100%;
  height: 100%;
  margin-top: 10px;
}
@media only screen and (min-width: 767px) {
.cta-image {
  margin: 0 auto;
}
.idea-area {
}
}
</style>