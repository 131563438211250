<template>
    <div class="features">
        <el-row :gutter="30">
            <el-col :span="12" :xs="24">
                <img src="@/assets/starlink.svg" class="cta-image" alt="a woman sitting under a tree next to a satellite that is collecting data"/>
            </el-col>
            <el-col :span="12" :xs="24">
                <div class="feature">
                    <div class="feature-title">
                        Create your namu
                    </div>
                    <div class="feature-content">
                        A namu is a list of websites that your searches are confined to. You can create endless namus for
                        different topics and collections, and then switch the search namu at any time.
                    </div>
                </div>
                <div class="feature">
                    <div class="feature-title">
                        Share your namu
                    </div>
                    <div class="feature-content">
                        You can share your namu with friends, family, and the world. Every namu that you create gets a unique hyperlink
                        that can be shared with others. You can also clone any namu you come across and edit it for your own needs.
                    </div>
                </div>
                 <div class="feature">
                    <div class="feature-title">
                        No ads
                    </div>
                    <div class="feature-content">
                        Including ads in your search results would ruin the curated namu experience. This is why we'll
                        never serve ads in your search results. You will get full access to namusearch for just a small subscription.
                    </div>
                </div>
                 <div class="feature">
                    <div class="feature-title">
                        No SEO spam
                    </div>
                    <div class="feature-content">
                        Searches are limited to your curated namu so you'll never see SEO-hacking, rank-hacking spam websites
                        in your search results (and if you do you can just remove that website from your namu).
                    </div>
                </div>
                 <div class="feature">
                    <div class="feature-title">
                        For Power Users
                    </div>
                    <div class="feature-content">
                        Power Users will be able to access search via an API. For advanced searches, you will be able to
                        use the full Lucene query syntax. We plan to make it possible
                        to fine-tune the ranking of a namu as well.
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.features {
    margin: 0 auto;
    max-width: 1000px;
    width: 80%;
}
.feature-title {
    font-weight: 600;
    color: #6C63FF;
    margin-bottom: 10px;
}
.feature {
    margin-top: 20px;
    margin-bottom: 40px;
}
.el-card {
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-header {
    font-weight: 600;
}
.card-body {
    min-height: 95px;
}
.cta-image {
  width: 100%;
  height: 100%
}
</style>
